import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../HomePage/Home.styles.css";
import myImage from "../../../images/phoenix-v2.svg";
import fcjcLogo from "../../../images/UChicago_TheLawSchool_FCJC_RGB Color.svg";



import uniLogo from "../../../images/UChicago_TheLawSchool_White RGB.svg";

import openSummary from "../../../images/FINAL/Freedom-Denied-Executive-Summary.pdf";

import downloadReport from "../../../images/FINAL/Freedom-Denied-Full-Report.pdf";




import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBFooter,
} from "mdb-react-ui-kit";



import Container from "@mui/material/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


import {
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";

import { TfiYoutube } from "react-icons/tfi";

import { IoLogoFacebook } from "react-icons/io";




function Summary() {


  return (
    <>
      {/* START OF TOP NAVIGATION */}
      {/* 👇️ TOP RED BAR NAVIGATION - LINKS TO THE MAIN UNIVERSITY SITE 👇️ */}
      <Navbar className="color-nav" variant="light">
        <Navbar.Brand href="https://www.uchicago.edu/">
          <img
            className="phoenixlogo"
            src={myImage}
            alt="The University of Chicago Phoenix"
          />
          <p className="UniTitle"> THE UNIVERSITY OF CHICAGO </p>
        </Navbar.Brand>
      </Navbar>

{/* 👇️ TOP CENTER LOGO - Federal Criminal Justice Clinic LOGO 👇️ */}
            <a href={"/"}>
                <div className="fcjclogobar">
                    <img
                        className="fcjclogo"
                        src={fcjcLogo}
                        alt="The University of Chicago The Law School Federal Criminal Justice Clinic"
                    />
                </div>
            </a>

      {/* 👇️ COLLAPSIBLE FOUR COLUMN NAVIGATION BAR 👇️ */}
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-center" style={{ flex: 1 }}>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/">
                HOME
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/report">
                FULL REPORT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/press">
                PRESS
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link
                className="navTitles"
                href={downloadReport}
                download="Freedom-Denied-Full-Report.pdf"
              >
                DOWNLOAD REPORT
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* 👇️ iFRAME - PDF REVIEWER 👇️ */}

      <div className="frameApp">
            <iframe src={`${openSummary}#view=fitH`} title="Freedom Denied Full Report" height="100%" width="100%" />
        </div>



      {/* 👇️ START OF FOOTER👇️ */}

      <MDBFooter className=" footerColor text-center text-lg-start text-muted">
        <div className="dropFooter">
          <section className="footerSection">
            <MDBContainer className="footerColor text-center text-md-start">
              <MDBRow className="">
                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <img src={uniLogo} alt="" className="uniLogo" />
                </MDBCol>

                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <a
                    className=" text-reset fw-bold text-uppercase"
                    href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                  >
                    <p className=" footerText1">
                      {" "}
                      Federal Criminal Justice Clinic{" "}
                    </p>
                  </a>

                  <p className=" footerTextInfo">
                    The Federal Criminal Justice Clinic (FCJC) is the nation’s
                    first legal clinic devoted to representing indigent clients
                    charged with federal felonies, pursuing impact litigation in
                    federal court, and engaging in systemic reform of the
                    federal criminal system to combat racial and socioeconomic
                    inequities.
                  </p>
                </MDBCol>

                <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <a
                      className=" text-reset fw-bold"
                      href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                    >
                      <p className=" footerText"> ABOUT US </p>
                    </a>
                  </h6>
                </MDBCol>

                <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <a
                      className="text-reset fw-bold"
                      href="https://www.law.uchicago.edu/faculty/siegler"
                    >
                      <p className=" footerText"> CONTACT US </p>
                    </a>
                  </h6>



                  {/* 👇️ FOOTER ICONS👇️ */}
<Container>
                                        <Row>
                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.facebook.com/UChicagoLaw/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <IoLogoFacebook style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://twitter.com/UChicagoLaw"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillTwitterCircle style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.linkedin.com/school/3878/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillLinkedin style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.instagram.com/uchicagolaw/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillInstagram style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.youtube.com/uchicagolawschool"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <TfiYoutube style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Container>

                  
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <div
            className="text-center p-4"
            style={{ backgroundColor: "rgb(51, 51, 51) ", color: "lightgrey" }}
          >
            © 2022 The University of Chicago
          </div>
        </div>
      </MDBFooter>
    </>
  );
}

export default Summary;
