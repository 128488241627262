// 👇️ SUMMARY
export const summary1 = 'In the first comprehensive national investigation of federal pretrial detention,';


export const profLink = 'Professor Alison Siegler';

export const andThe = ' and the';


export const fcjc = 'Federal Criminal Justice Clinic';


export const summary2 = ' seek to understand why federal jailing rates are astronomically high, with three out of every four people jailed before trial—far more than in state systems.'


export const summary3 = ' The answer is shocking.'

export const inS = 'In'

export const reportTitle = ' Freedom Denied: How the Culture of Detention Created a Federal Jailing Crisis'


export const summary4 = ', the Clinic finds that federal judges routinely violate the very bail laws that they are tasked with upholding, which drives up detention rates, jails people for poverty, and exacerbates racial disparities. Courtwatching data and first-hand accounts from judges and lawyers reveal that a culture of detention pervades federal courtrooms. Courthouse custom overrides the written law, eroding the presumption of innocence.'


export const summary5 = ' The result is a federal jailing crisis that fuels mass incarceration and inflicts lasting harms on presumptively innocent people, families, communities, and society. Federal judges have a responsibility to vindicate the rights of the accused and restore the norm of liberty enshrined in federal law.'

// 👇️ PRETRIAL INFO👇️ 

export const pretrialTitle = 'Pretrial jailing is now the norm rather than the exception.'

// 👇️ FIGURE 1 INFO👇️ 

export const figure1Title = 'Figure 1: Federal Pretrial Detention Rates Have Skyrocketed Since the BRA Was Enacted (1983-2019).'

export const figure1Body = '“Although the existing evidence shows that the federal bail system is in crisis, it does not show why or how that crisis is occurring.” “This Report reveals a fractured and freewheeling federal pretrial detention system that has strayed far from the norm of pretrial liberty.”'


// 👇️ FIGURE 5 INFO👇️ 


export const rec1Title = 'Judges Must Follow the Correct Legal Standard at the Initial Appearance Hearing and Stop Jailing People Unlawfully.'

export const rec2Title = 'Judges Must Stop Unlawfully Jailing Poor People Without Lawyers at the Initial Appearance Hearing.'

export const rec3Title = 'Judges Must Follow the Correct Legal Standard in Presumption-of-Detention Cases to Reduce Racial Disparities and High Federal Jailing Rates.'

export const rec4Title = 'Judges Must Stop Unlawfully Jailing People for Poverty Through Excessive Financial Conditions.'




export const figure5Title = 'Figure 5: Problematic Feedback Loop at Initial Appearance'


// 👇️ FIGURE 9 INFO👇️ 

export const figure9Title = 'Figure 9: At the Initial Appearance, Prosecutors Regularly Request Detention on Improper Grounds and Judges Detain People Unlawfully.'


// 👇️ FIGURE 13 INFO👇️ 

export const figure13Title = 'Figure 13: Unlawful Detentions Are Carried Out in a Racially Disparate Way.'


// 👇️ FIGURE 6 INFO👇️ 

export const figure6Title = 'Figure 6: There Is a Nationwide Access-to-Counsel Crisis in the Federal System.'


// 👇️ FIGURE 18 INFO👇️ 

export const figure18Title = 'Figure 18: When Arrestees Were Forced to Proceed Without Counsel at Their Appearance, There Was a Dramatic Increase in Pretrial Detention.'



// 👇️ FIGURE 22 INFO👇️ 

export const figure22Title = 'Figure 22: The Presumption of Detention May Contribute to Racial Disparities in the Federal System.'


// 👇️ FIGURE 23 INFO👇️ 

export const figure23Title = 'Figure 23: Judges Impose Burdensome Financial Requirements and Jail People for Poverty.'



// 👇️ FIGURE 26 INFO👇️ 

export const figure26Title = 'Figure 26: Excessive Financial Conditions of Release Have a Disproportionate Racial Impact.'

