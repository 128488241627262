import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../HomePage/Home.styles.css";
import "../Press/Press.styles.css";
import myImage from "../../../images/phoenix-v2.svg";
import fcjcLogo from "../../../images/UChicago_TheLawSchool_FCJC_RGB Color.svg";
import reportBanner from "../../../images/FINAL_Web_banner_TEXT.svg";
import uniLogo from "../../../images/UChicago_TheLawSchool_White RGB.svg";
import reason from "../../../images/reason.jpg";
import usaToday from "../../../images/USA-Today.png";
import wbez from "../../../images/wbez.svg";
import wttw from "../../../images/WTTW.png";
import nyt from "../../../images/nytlogo.png";
import Button from "react-bootstrap/Button";
import downloadReport from "../../../images/FINAL/Freedom-Denied-Full-Report.pdf";
import Card from "react-bootstrap/Card";
import Container from "@mui/material/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
    AiFillTwitterCircle,
    AiFillLinkedin,
    AiFillInstagram,
} from "react-icons/ai";

import { TfiYoutube } from "react-icons/tfi";

import { IoLogoFacebook } from "react-icons/io";

import { MDBContainer, MDBRow, MDBCol, MDBFooter } from "mdb-react-ui-kit";

function Press() {
    /* 👇️ CONST TO MAKE MULTIPLE MODAL WORKS👇️ - CURRENTLY NOT SWITCHING TO MULTIPLE MODALS - FIX PLEASE!*/

    return (
        <>
            {/* START OF TOP NAVIGATION */}
            {/* 👇️ TOP RED BAR NAVIGATION - LINKS TO THE MAIN UNIVERSITY SITE 👇️ */}
            <Navbar className="color-nav" variant="light">
                <Navbar.Brand href="https://www.uchicago.edu/">
                    <img
                        className="phoenixlogo"
                        src={myImage}
                        alt="The University of Chicago Phoenix"
                    />
                    <p className="UniTitle"> THE UNIVERSITY OF CHICAGO </p>
                </Navbar.Brand>
            </Navbar>

            {/* 👇️ TOP CENTER LOGO - Federal Criminal Justice Clinic LOGO 👇️ */}
            <a href={"/"}>
                <div className="fcjclogobar">
                    <img
                        className="fcjclogo"
                        src={fcjcLogo}
                        alt="The University of Chicago The Law School Federal Criminal Justice Clinic"
                    />
                </div>
            </a>

            {/* 👇️ COLLAPSIBLE FOUR COLUMN NAVIGATION BAR 👇️ */}
            <Navbar collapseOnSelect expand="lg">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="justify-content-center" style={{ flex: 1 }}>
                        <Nav.Item className="navBox">
                            <Nav.Link className="navTitles" href="/">
                                HOME
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="navBox">
                            <Nav.Link className="navTitles" href="/summary">
                                EXECUTIVE SUMMARY
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="navBox">
                            <Nav.Link className="navTitles" href="/report">
                                FULL REPORT
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="navBox">
                            <Nav.Link
                                className="navTitles"
                                href={downloadReport}
                                download="Freedom-Denied-Full-Report.pdf"
                            >
                                DOWNLOAD REPORT
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {/* 👇️ FULL REPORT BANNER PLUS DOWNLOAD 👇️ */}
            <a href="/report">
                <div className="reportBanner">
                    <img
                        className="reportBannerImg"
                        src={reportBanner}
                        alt="Freedom Denied How the Culture of Detention Created a Federal Jailing Crisis"
                    />
                </div>
            </a>
            {/* END OF TOP NAVIGATION */}

            {/* 👇️ PRESS #1 SECTION 👇️ */}
            <Container fixed className="pressContainer">
                <Card className="text-center">
                    <Card.Body>
                        {/* 👇️ PRESS SECTION 👇️ */}
                        <Row xs={1} md={2} className="g-4">
                            
{/* 👇️ ADD NEW PRESS SECTIONS ABOVE THE LASTEST ONE - RIGHT HERE!!!👇️ */}

                            {/* 👇️ PRESS - REASON SECTION 👇️ */}
                            <Col>
                                <Card>
                                    <Card.Header>REASON</Card.Header>
                                    <Card.Img
                                        variant="top"
                                        src={reason}
                                        alt="USA Today Logo"
                                        className="pressLogos"
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Freedom Denied Part 1: How the Culture of Detention
                                            Created a Federal Jailing Crisis
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text></Card.Text>
                                        <a
                                            href="https://reason.com/volokh/2023/01/09/freedom-denied-part-1-how-the-culture-of-detention-created-a-federal-jailing-crisis/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button variant="dark"> READ ARTICLE </Button>
                                        </a>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        January 9, 2023
                                    </Card.Footer>
                                </Card>
                            </Col>

                            {/* 👇️ PRESS - USA TODAY SECTION 👇️ */}
                            <Col>
                                <Card>
                                    <Card.Header>USA TODAY</Card.Header>
                                    <Card.Img
                                        variant="top"
                                        src={usaToday}
                                        alt="USA Today Logo"
                                        className="pressLogos"
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            Study: Federal magistrates, prosecutors misunderstand bail
                                            law, jailing people who should go free
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text></Card.Text>
                                        <a
                                            href="https://www.usatoday.com/story/news/politics/2022/12/07/federal-judges-misapply-bail-law-illegally-jail-arrestees-study-says/10798949002/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button variant="dark"> READ ARTICLE </Button>
                                        </a>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        December 7, 2022
                                    </Card.Footer>
                                </Card>
                            </Col>

                            {/* 👇️ PRESS WBEZ SECTION 👇️ */}
                            <Col>
                                <Card>
                                    <Card.Header>WBEZ</Card.Header>
                                    <Card.Img
                                        variant="top"
                                        src={wbez}
                                        alt="WBEZ Logo"
                                        className="wbezLogo"
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            New report shows how judges unlawfully detain people
                                            awaiting federal trial
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text></Card.Text>
                                        <a
                                            href="https://www.wbez.org/stories/new-report-shows-how-judges-unlawfully-detain-people-awaiting-federal-trial/e424340e-d231-4bec-bc7e-f1e58440dd5d"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button variant="dark"> READ ARTICLE </Button>
                                        </a>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        December 9, 2022
                                    </Card.Footer>
                                </Card>
                            </Col>

                            {/* 👇️ PRESS - WTTW CHICAGO SECTION 👇️ */}
                            <Col>
                                <Card>
                                    <Card.Header>CHICAGO TONIGHT</Card.Header>
                                    <Card.Img
                                        variant="top"
                                        src={wttw}
                                        alt="wttw Logo"
                                        className="wbezLogo"
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            New U. of C. Report Finds Federal Judges Jailing
                                            Defendants Awaiting Trial at Increasingly High Rates
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text></Card.Text>
                                        <a
                                            href="https://news.wttw.com/2022/12/12/new-u-c-report-finds-federal-judges-jailing-defendants-awaiting-trial-increasingly-high"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button variant="dark"> READ ARTICLE </Button>
                                        </a>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        December 12, 2022
                                    </Card.Footer>
                                </Card>
                            </Col>

                            {/* 👇️ PRESS - THE NEW YORK TIMES SECTION 👇️ */}
                            <Col>
                                <Card>
                                    <Card.Header>THE NEW YORK TIMES</Card.Header>
                                    <Card.Img
                                        variant="top"
                                        src={nyt}
                                        alt="The New York Times Logo"
                                        className="wbezLogo"
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            How Did the 'Worst of the Worst' Become 3 Out of 4?
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text></Card.Text>
                                        <a
                                            href="https://www.nytimes.com/2021/02/24/opinion/merrick-garland-bail-reform.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button variant="dark"> READ ARTICLE </Button>
                                        </a>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">Feb 24, 2021</Card.Footer>
                                </Card>
                            </Col>
                            {/* END OF PRESS - THE NEW YORK TIMES SECTION */}
                        </Row>
                    </Card.Body>
                </Card>
            </Container>

            {/* 👇️ START OF FOOTER👇️ */}
            <MDBFooter className=" footerColor text-center text-lg-start text-muted">
                <div className="dropFooter">
                    <section className="footerSection">
                        <MDBContainer className="footerColor text-center text-md-start">
                            <MDBRow className="">
                                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                                    <img src={uniLogo} alt="" className="uniLogo" />
                                </MDBCol>

                                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                                    <a
                                        className=" text-reset fw-bold text-uppercase"
                                        href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                                    >
                                        <p className=" footerText1">
                                            {" "}
                                            Federal Criminal Justice Clinic{" "}
                                        </p>
                                    </a>

                                    <p className=" footerTextInfo">
                                        The Federal Criminal Justice Clinic (FCJC) is the nation’s
                                        first legal clinic devoted to representing indigent clients
                                        charged with federal felonies, pursuing impact litigation in
                                        federal court, and engaging in systemic reform of the
                                        federal criminal system to combat racial and socioeconomic
                                        inequities.
                                    </p>
                                </MDBCol>

                                <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4">
                                        <a
                                            className=" text-reset fw-bold"
                                            href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                                        >
                                            <p className=" footerText"> ABOUT US </p>
                                        </a>
                                    </h6>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4">
                                        <a
                                            className="text-reset fw-bold"
                                            href="https://www.law.uchicago.edu/faculty/siegler"
                                        >
                                            <p className=" footerText"> CONTACT US </p>
                                        </a>
                                    </h6>
                                    {/* 👇️ FOOTER ICONS👇️ */}
                                    <Container>
                                        <Row>
                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.facebook.com/UChicagoLaw/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <IoLogoFacebook style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://twitter.com/UChicagoLaw"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <AiFillTwitterCircle style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.linkedin.com/school/3878/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <AiFillLinkedin style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.instagram.com/uchicagolaw/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <AiFillInstagram style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.youtube.com/uchicagolawschool"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <TfiYoutube style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div
                        className="text-center p-4"
                        style={{ backgroundColor: "rgb(51, 51, 51) ", color: "lightgrey" }}
                    >
                        © 2022 The University of Chicago
                    </div>
                </div>
            </MDBFooter>
        </>
    );
}

export default Press;
