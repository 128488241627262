// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  \n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'PT Sans', sans-serif;\n \n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;;EAEE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,kCAAkC;;AAEpC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');\n\n* {\n  \n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'PT Sans', sans-serif;\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
