import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../HomePage/Home.styles.css";
import myImage from "../../../images/phoenix-v2.svg";
import fcjcLogo from "../../../images/UChicago_TheLawSchool_FCJC_RGB Color.svg";
import logoBanner from "../../../images/Banner_With_DownloadButton.png";
import reportBanner from "../../../images/FINAL_Web_banner_TEXT.svg";
import uniLogo from "../../../images/UChicago_TheLawSchool_White RGB.svg";
import figure1 from "../../../images/V2/Figure1_V2.svg";
import figure5 from "../../../images/V2/Figure5_V2.svg";
import figure6 from "../../../images/V2/Figure6_V2.svg";
import figure9 from "../../../images/V2/Figure9_V2.svg";
import figure13 from "../../../images/V2/Figure13_V2.svg";
import figure18 from "../../../images/V2/Figure18_V2.svg";
import figure22 from "../../../images/V2/Figure22_V2.svg";
import figure23 from "../../../images/V3/Figure23_V3.svg";
import figure26 from "../../../images/V2/Figure26_V2.svg";
import downloadReport from "../../../images/FINAL/Freedom-Denied-Full-Report.pdf";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Container from "@mui/material/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
import { TfiYoutube } from "react-icons/tfi";
import { IoLogoFacebook } from "react-icons/io";
import {
  andThe,
  fcjc,
  profLink,
  reportTitle,
  summary1,
  summary2,
  summary3,
  summary4,
  summary5,
  inS,
  pretrialTitle,
  figure1Title,
  rec1Title,
  rec2Title,
  rec3Title,
  rec4Title,
  figure5Title,
  figure9Title,
  figure13Title,
  figure6Title,
  figure18Title,
  figure22Title,
  figure23Title,
  figure26Title,
} from "./Data";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBFooter,
  MDBTable,
  MDBTableBody,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

function Home() {

  return (
    <>
      {/* START OF TOP NAVIGATION */}
      {/* 👇️ TOP RED BAR NAVIGATION - LINKS TO THE MAIN UNIVERSITY SITE 👇️ */}
      <Navbar className="color-nav" variant="light">
        <Navbar.Brand href="https://www.uchicago.edu/">
          <img
            className="phoenixlogo"
            src={myImage}
            alt="The University of Chicago Phoenix"
          />
          <p className="UniTitle"> THE UNIVERSITY OF CHICAGO </p>
        </Navbar.Brand>
      </Navbar>

      {/* 👇️ TOP CENTER LOGO - Federal Criminal Justice Clinic LOGO 👇️ */}
      <a href={"/"}>
          <div className="fcjclogobar">
              <img
                className="fcjclogo"
                src={fcjcLogo}
                alt="The University of Chicago The Law School Federal Criminal Justice Clinic"
              />
          </div>
      </a>
    




      {/* 👇️ COLLAPSIBLE FOUR COLUMN NAVIGATION BAR 👇️ */}
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-center" style={{ flex: 1 }}>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/summary">
                EXECUTIVE SUMMARY
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/report">
                FULL REPORT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link className="navTitles" href="/press">
                PRESS
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navBox">
              <Nav.Link
                className="navTitles"
                href={downloadReport}
                download="Freedom-Denied-Full-Report.pdf"
              >
                DOWNLOAD REPORT
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* 👇️ FULL REPORT BANNER PLUS DOWNLOAD 👇️ */}
      <a href="/report">
        <div className="reportBanner">
          <img
            className="reportBannerImg"
            src={reportBanner}
            alt="Freedom Denied How the Culture of Detention Created a Federal Jailing Crisis"
          />
        </div>
      </a>
      {/* END OF TOP NAVIGATION */}

      {/* START OF MAIN BODY */}
      {/* 👇️ TOP THREE NAV BOXES - ADD LINKS TO THIS SECTION 👇️ */}
      <div className="greyBack">
        <Container fixed className="cardAsGroup">
          <CardGroup>
            <Card className="cardBorder">
              <Card.Body>
                <a
                  href={"/summary"}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    textAlign: "center",
                    fontSize: "20px",
                    alignContent: "center",
                  }}
                >
                  <Card.Title className="cardTitleTOP">
                    EXECUTIVE SUMMARY
                  </Card.Title>
                </a>
              </Card.Body>
            </Card>
            <Card className="cardBorder">
              <Card.Body>
                <a
                  href={"/report"}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    textAlign: "center",
                    fontSize: "20px",
                    alignContent: "center",
                  }}
                >
                  <Card.Title className="cardTitleTOP">FULL REPORT</Card.Title>
                </a>
              </Card.Body>
            </Card>
            <Card className="cardBorder">
              <Card.Body>
                <a
                  href={"/findings"}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    textAlign: "center",
                    fontSize: "20px",
                    alignContent: "center",
                  }}
                >
                  <Card.Title className="cardTitle">
                    FINDING & RECOMMENDATIONS
                  </Card.Title>
                </a>
              </Card.Body>
            </Card>
          </CardGroup>
        </Container>

        {/* 👇️ SUMMARY👇️ */}
        <Container fixed className="mainContainer">
          <div className="summaryBox">
            {summary1}
            <a
              href="https://www.law.uchicago.edu/faculty/siegler"
              className="summaryLink"
            >
              {profLink}
            </a>
            {andThe}
            <a
              href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
              className="summaryLink"
            >
              {fcjc}
            </a>
            {summary2}
          </div>
          <div className="shockingText">{summary3}</div>
          <div className="summaryBox2">
            {inS}
            <i>{reportTitle}</i>
            {summary4}
          </div>
          <div className="summaryBox2">{summary5}</div>
        </Container>

        {/* 👇️ FULL REPORT BANNER PLUS DOWNLOAD - ADD THE DOWNLOAD REPORT BUTTON WITH SUITE👇️ */}
        <a href={downloadReport} download="Freedom-Denied-Full-Report.pdf">
          <div className="downloadBanner">
            <img
              className="downloadBannerImg"
              src={logoBanner}
              alt="Freedom Denied How the Culture of Detention Created a Federal Jailing Crisis"
            />
          </div>
        </a>

        {/* 👇️ TOP ENTRY - PRETRIAL JAILING SECTION 👇️ */}
        <Container fixed className="mainContainer">
          <div className="reportBoxTitles">{pretrialTitle}</div>

          <div className="jailingBox">
            <div className="figureTitleTOP">{figure1Title}</div>
            <div className="figureContanier">

{/* 👇️ FIGURE 1 CONTAINER👇️ */}
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure1} alt="1983: 23.8%, 1985: 29%, 1993: 42%, 1994: 42%, 1995: 43%, 1996: 44%, 1997: 50%, 1998: 51%, 1999: 51%, 2000: 52%, 2001: 53%, 2002: 55%, 2003: 57%, 2004: 60%, 2005: 61%, 2006: 61%, 2007: 62%, 2008: 63%, 2009: 66%, 2010: 66%, 2011: 66%, 2012: 72%, 2013: 72%, 2014: 73%, 2015: 73%, 2016: 73%, 2017: 72%, 2018: 75%, 2019: 74.8%
" className="w-100 hover-shadow" />
                    {/* 👇️ START OF LEFT MODAL - FIGURE 1 👇️ - CURRENTLY NOT SWITCHING TO MULTIPLE MODALS - FIX PLEASE! */}
                  </MDBCol>

                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “Although the existing evidence shows that{" "}
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          the federal bail system is in crisis
                        </span>
                        , it does not show why or how that crisis is occurring.”
                      </p>

                      <p>
                        “This Report reveals a fractured and freewheeling
                        federal pretrial detention system that has{" "}
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          strayed far from the norm of pretrial liberty.
                        </span>
                        "
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
        </Container>

{/* 👇️ FINDING AND REC NAV BAR👇️ */}

        <div className="recNavBarMain">
          <div className="recNavBar">
            <p className="recNavBarTitle">FINDINGS & RECOMMENDATIONS</p>

            <MDBRow className="row-cols-1 row-cols-md-4 g-4">
{/* 👇️ RECOMMENDATION 1 NAV BAR👇️ */}
              <MDBCol>
                <MDBCard
                  className="h-100 text-white mb-3"
                  style={{ backgroundColor: "rgb(80, 85, 104)" }}
                >
                  <a
                    href={"/recommendation1"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "white",
                          textDecoration: "none",
                          textAlign: "center",
                          fontSize: "30px",
                        }}
                      >
                        - 1 -
                      </MDBCardTitle>
                      <MDBCardText>
                        Judges Must Follow the Correct Legal Standard at the
                        Initial Appearance Hearing and Stop Jailing People
                        Unlawfully.
                      </MDBCardText>

                      <MDBTable borderless>
                        <MDBTableBody
                          style={{ color: "white", textAlign: "left" }}
                        >
                          <tr>
                            <th scope="row"></th>
                            <td>TAKEAWAYS</td>
                            <td>P.84</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE LAW</td>
                            <td>P.87</td>
                          </tr>

                          <tr>
                            <th scope="row"></th>
                            <td>THE PRACTICE</td>
                            <td>P.93</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE SOLUTION</td>
                            <td>P.107</td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </a>
                </MDBCard>
              </MDBCol>

              {/* 👇️ RECOMMENDATION 2 NAV BAR👇️ */}
              <MDBCol>
                <MDBCard
                  className="h-100 text-white mb-3"
                  style={{ backgroundColor: "rgb(80, 85, 104)" }}
                >
                  <a
                    href={"/recommendation2"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "white",
                          textDecoration: "none",
                          textAlign: "center",
                          fontSize: "30px",
                        }}
                      >
                        - 2 -
                      </MDBCardTitle>
                      <MDBCardText>
                        Judges Must Stop Unlawfully Jailing Poor People Without
                        Lawyers at the Initial Appearance Hearing.
                      </MDBCardText>

                      <MDBTable borderless>
                        <MDBTableBody
                          style={{ color: "white", textAlign: "left" }}
                        >
                          <tr>
                            <th scope="row"></th>
                            <td>TAKEAWAYS</td>
                            <td>P.116</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE LAW</td>
                            <td>P.118</td>
                          </tr>

                          <tr>
                            <th scope="row"></th>
                            <td>THE PRACTICE</td>
                            <td>P.128</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE SOLUTION</td>
                            <td>P.141</td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </a>
                </MDBCard>
              </MDBCol>

              {/* 👇️ RECOMMENDATION 3 NAV BAR👇️ */}
              <MDBCol>
                <MDBCard
                  className="h-100 text-white mb-3"
                  style={{ backgroundColor: "rgb(80, 85, 104)" }}
                >
                  <a
                    href={"/recommendation3"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "white",
                          textDecoration: "none",
                          textAlign: "center",
                          fontSize: "30px",
                        }}
                      >
                        - 3 -
                      </MDBCardTitle>
                      <MDBCardText>
                        Judges Must Follow the Correct Legal Standard in
                        Presumption-of-Detention Cases to Reduce Racial
                        Disparities and High Federal Jailing Rates.
                      </MDBCardText>

                      <MDBTable borderless>
                        <MDBTableBody
                          style={{ color: "white", textAlign: "left" }}
                        >
                          <tr>
                            <th scope="row"></th>
                            <td>TAKEAWAYS</td>
                            <td>P.148</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE LAW</td>
                            <td>P.151</td>
                          </tr>

                          <tr>
                            <th scope="row"></th>
                            <td>THE PRACTICE</td>
                            <td>P.158</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE SOLUTION</td>
                            <td>P.168</td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </a>
                </MDBCard>
              </MDBCol>

              {/* 👇️ RECOMMENDATION 4 NAV BAR👇️ */}
              <MDBCol>
                <MDBCard
                  className="h-100 text-white mb-3"
                  style={{ backgroundColor: "rgb(80, 85, 104)" }}
                >
                  <a
                    href={"/recommendation4"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "white",
                          textDecoration: "none",
                          textAlign: "center",
                          fontSize: "30px",
                        }}
                      >
                        - 4 -
                      </MDBCardTitle>
                      <MDBCardText>
                        Judges Must Stop Unlawfully Jailing People for Poverty
                        Through Excessive Financial Conditions.
                      </MDBCardText>

                      <MDBTable borderless>
                        <MDBTableBody
                          style={{ color: "white", textAlign: "left" }}
                        >
                          <tr>
                            <th scope="row"></th>
                            <td>TAKEAWAYS</td>
                            <td>P.172</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE LAW</td>
                            <td>P.174</td>
                          </tr>

                          <tr>
                            <th scope="row"></th>
                            <td>THE PRACTICE</td>
                            <td>P.180</td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td>THE SOLUTION</td>
                            <td>P.190</td>
                          </tr>
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </a>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </div>
        </div>

        {/* 👇️ TOP ENTRY - PRETRIAL JAILING SECTION 👇️ */}
        <Container fixed className="mainContainer">
          <p className="findingTitles">Finding & Recommendation 1:</p>

          <div className="findingBoxTitles">{rec1Title}</div>

          <div className="jailingBox">
            {/* 👇️ FIGURE 5 CONTAINER👇️ */}
            <div className="figureTitleTOP">{figure5Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure5} alt="The proscecutor cites improper grounds for detention at initial appearance. The defense attorney does not object. The judge neither questions the prosecutor nor adheres to the statutory requirements. Some arrestees are jailed unlawfully, without a valid basis under section 3142f. Judges don't realize that they are jailing arrestees unlawfully, the practice further diverges from the law, and pretrial detention rates rise.
" className="w-100 hover-shadow" />
                  </MDBCol>

                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “Our primary explanation for the legal violations
                        documented in this Report is the phenomenon we have
                        labeled
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          the culture of detention
                        </span>
                        . . . . Even when the [statute] contains clear
                        instructions, judges and prosecutors frequently ignore
                        those instructions in favor of longstanding district
                        practices,
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          substituting courtroom habits for the plain text of
                          the statute{" "}
                        </span>
                        and overincarcerating people in the process. . . . [O]ne
                        judge we interviewed justified those deviations by
                        saying: "
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          Oh, that’s just the way we do it.
                        </span>{" "}
                        "
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>

            {/* 👇️ FIGURE 9 CONTAINER👇️ */}
            <div className="figureTitles">{figure9Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “Our most troubling finding was that, in 12% of Initial
                        Appearances where the prosecutor was seeking detention,
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          judges detained people illegally
                        </span>
                        . . . . Such unlawful detentions are the inevitable
                        consequence of the Initial Appearance feedback loop we
                        identify.”
                      </p>
                    </div>
                  </MDBCol>

                  {/* 👇️ RIGHT IMAGE SECTION 👇️ */}
                  <MDBCol sm="6" md="5" lg="6">
                    <img src={figure9} alt="233 out of 243 cases where the prosecutor sought detetions. In 81% of these cases, the prosecutor failed to cite a valid statutory basis for detention (189 out of 233). Judges detained arrestees in over 99% of Initial Appearances where the prosecutor failed to cite a valid basis for detention. In 12% of these detetions were illegal under the BRA as no statutory basis for detention existed under section 3142f (27 out of 233). 81% of these individuals were detained for the duration of the case (22 out of 27).
" className="w-100 hover-shadow" />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>

            {/* 👇️ FIGURE 13 CONTAINER👇️ */}
            <div className="figureTitles">{figure13Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure13} alt="56% of Black arrestees detained illegally, 45% of Latino arrestees detained illegally, 21% of white arrestees detained illegally
" className="w-100 hover-shadow" />
                  </MDBCol>
                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “Moreover,
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          these unlawful detentions were carried out in a
                          racially disparate way
                        </span>
                        . . . . The unlawful detentions we observed are just the
                        tip of the iceberg. . . . This is not just an isolated
                        situation in which a few judges or attorneys slightly
                        misunderstand the law; rather, there is a
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          pervasive, systemic deprivation of liberty{" "}
                        </span>
                        that is
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          not authorized{" "}
                        </span>
                        by statute or case law.”
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
        </Container>

        {/* 👇️ TOP ENTRY - PRETRIAL JAILING SECTION 👇️ */}
        <Container fixed className="mainContainer">
          <p className="findingTitles">Finding & Recommendation 2:</p>

          <div className="findingBoxTitles">{rec2Title}</div>

          <div className="jailingBox">
            {/* 👇️ FIGURE 5 CONTAINER👇️ */}
            <div className="figureTitleTOP">{figure6Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure6} alt="More than one quarter of the 94 federal district courts nationwide do not ensure that every single arrestee is reppreseted by a lawyer the initial appearance.
" className="w-100 hover-shadow" />
                  </MDBCol>

                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “During our courtwatching, we observed judges
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          violating federal law{" "}
                        </span>
                        by holding uncounseled Initial Appearances where
                        indigent individuals appeared <i>pro se </i>across from
                        federal prosecutors, and by
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          jailing indigent individuals{" "}
                        </span>
                        without first appointing them a lawyer.”
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>

            {/* 👇️ FIGURE 9 CONTAINER👇️ */}
            <div className="figureTitles">{figure18Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “In our study,
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          every arrestee{" "}
                        </span>
                        who was deprived of a lawyer at the Initial Appearance
                        was jailed, and
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          nearly all were Black or Latino.
                        </span>
                        ”
                      </p>
                    </div>
                  </MDBCol>

                  {/* 👇️ RIGHT IMAGE SECTION 👇️ */}
                  <MDBCol sm="6" md="5" lg="6">
                    <img src={figure18} alt="Fully represented individuals were detained 67% of the time. Partially represented individuals were detained 89% of the time. All unrepresented individuals were detained.
" className="w-100 hover-shadow" />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
        </Container>

        {/* 👇️ TOP ENTRY - PRETRIAL JAILING SECTION 👇️ */}
        <Container fixed className="mainContainer">
          <p className="findingTitles">Finding & Recommendation 3:</p>

          <div className="findingBoxTitles">{rec3Title}</div>

          <div className="jailingBox">
            {/* 👇️ FIGURE 5 CONTAINER👇️ */}
            <div className="figureTitleTOP">{figure22Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure22} alt="Judges detained people of color at higher rates than white individuals in presumption cases. The detention rate involving people of color was 73%, and 68% for white individuals.
" className="w-100 hover-shadow" />
                  </MDBCol>

                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “[W]hen we observed judges and prosecutors making
                        serious legal mistakes regarding the presumption,
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          the arrestee was always a person of color.
                        </span>
                        "
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
        </Container>

        {/* 👇️ TOP ENTRY - PRETRIAL JAILING SECTION 👇️ */}
        <Container fixed className="mainContainer">
          <p className="findingTitles">Finding & Recommendation 4:</p>

          <div className="findingBoxTitles">{rec4Title}</div>

          <div className="jailingBox">
            {/* 👇️ FIGURE 5 CONTAINER👇️ */}
            <div className="figureTitleTOP">{figure23Title}</div>
            <div className="figureContanier">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol sm="6" md="5" lg="6">
                    {/* 👇️ LEFT IMAGE SECTION 👇️ */}
                    <img src={figure23} alt="In 37% of cases in our study, judges imposed financial conditions (127 out of 343). In 91% of cases where financial conditions were imposed, judges required arrestees to post a secured bond (116 out of 127). Arrestees did not have the money to meet financial conditions in 36% of cases (46 out of 127). For these individuals, the financial conditions acted as de facto detention orders, in violation of the law.
" className="w-100 hover-shadow" />
                  </MDBCol>

                  <MDBCol sm="6" md="5" lg="6" offsetMd="1" offsetLg="0">
                    <div className="figureRight">
                      <p>
                        “Our study found that federal courts regularly impose
                        inequitable and burdensome financial conditions,
                        perpetuating a system in which
                        <span
                          style={{
                            color: "red",
                            fontFamily:
                              "Faustina, Georgia, Garamond, Times New Roman, serif",
                          }}
                        >
                          {" "}
                          money buys freedom and poverty ensures incarceration.
                        </span>
                        "
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>

            {/* 👇️ FIGURE 9 CONTAINER👇️ */}
            <div className="figureTitles">{figure26Title}</div>
            <div className="figureContanier">
              <img
                src={figure26}
                alt="95% of secured bonds were imposed against people of color."
                className="hover-shadow"
                style={{ width: "50%" }}
              />
            </div>
          </div>
        </Container>

        {/* 👇️ FULL REPORT BANNER PLUS DOWNLOAD - ADD THE DOWNLOAD REPORT BUTTON WITH SUITE👇️ */}
        <a href={downloadReport} download="Freedom-Denied-Full-Report.pdf">
          <div className="downloadBanner">
            <img
              className="downloadBannerImg"
              src={logoBanner}
              alt="Freedom Denied How the Culture of Detention Created a Federal Jailing Crisis"
            />
          </div>
        </a>

        {/* 👇️ DIV BELOW ENDS className="greyBack"👇️ */}
      </div>

      {/* 👇️ START OF FOOTER👇️ */}

      <MDBFooter className=" footerColor text-center text-lg-start text-muted">
        <div className="dropFooter">
          <section className="footerSection">
            <MDBContainer className="footerColor text-center text-md-start">
              <MDBRow className="">
                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <img src={uniLogo} alt="" className="uniLogo" />
                </MDBCol>

                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <a
                    className=" text-reset fw-bold text-uppercase"
                    href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                  >
                    <p className=" footerText1">
                      {" "}
                      Federal Criminal Justice Clinic{" "}
                    </p>
                  </a>

                  <p className=" footerTextInfo">
                    The Federal Criminal Justice Clinic (FCJC) is the nation’s
                    first legal clinic devoted to representing indigent clients
                    charged with federal felonies, pursuing impact litigation in
                    federal court, and engaging in systemic reform of the
                    federal criminal system to combat racial and socioeconomic
                    inequities.
                  </p>
                </MDBCol>

                <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <a
                      className=" text-reset fw-bold"
                      href="https://www.law.uchicago.edu/clinics/mandel/fcjc"
                    >
                      <p className=" footerText"> ABOUT US </p>
                    </a>
                  </h6>
                </MDBCol>

                <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <a
                      className="text-reset fw-bold"
                      href="https://www.law.uchicago.edu/faculty/siegler"
                    >
                      <p className=" footerText"> CONTACT US </p>
                    </a>
                  </h6>


{/* 👇️ FOOTER ICONS👇️ */}
<Container>
                                        <Row>
                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.facebook.com/UChicagoLaw/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <IoLogoFacebook style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://twitter.com/UChicagoLaw"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillTwitterCircle style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.linkedin.com/school/3878/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillLinkedin style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.instagram.com/uchicagolaw/"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <AiFillInstagram style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>

                                            <Col>
                                                <a
                                                    className="icons"
                                                    href="https://www.youtube.com/uchicagolawschool"
                                                    target="_blank" rel="noreferrer" 
                                                >
                                                    <TfiYoutube style={{ fontSize: "25px" }} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Container>




                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <div
            className="text-center p-4"
            style={{ backgroundColor: "rgb(51, 51, 51) ", color: "lightgrey" }}
          >
            © 2022 The University of Chicago
          </div>
        </div>
      </MDBFooter>
    </>
  );
}

export default Home;
