import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import Preloader from "./components/Preloader/Preloader";
import Home from './components/pages/HomePage/Home';
import Summary from './components/pages/Summary/Summary';
import Report from './components/pages/Report/Report';
import Findings from './components/pages/Findings/Findings';
import Recommendation1 from './components/pages/Recommendation1/Recommendation1';
import Recommendation2 from './components/pages/Recommendation2/Recommendation2';
import Recommendation3 from './components/pages/Recommendation3/Recommendation3';
import Recommendation4 from './components/pages/Recommendation4/Recommendation4';
import NotFound from './components/pages/NotFound/NotFound';
import Press from './components/pages/Press/Press';








function App() {

  return (
      <Router >
        <div className='App'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/summary' element={<Summary/>} />
              <Route path='/report' element={<Report/>} />
              <Route path='/press' element={<Press/>} />
              <Route path='/findings' element={<Findings/>} />
              <Route path='/recommendation1' element={<Recommendation1/>} />
              <Route path='/recommendation2' element={<Recommendation2/>} />
              <Route path='/recommendation3' element={<Recommendation3/>} />
              <Route path='/recommendation4' element={<Recommendation4/>} />
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </div>
      </Router>
  );
}

export default App;